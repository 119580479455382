/* Container */
.userContainer {
    margin-top: 400px;
    width: 100%;
    padding: 2rem 1rem;
    margin-top: 60px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }
  
  /* Table Container */
  .table-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #e5e7eb;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    background-color: #ffffff;
  }
  
  .overflow-table {
    overflow-x: auto;
  }
  
  /* Table */
  .user-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }
  
  .table-header {
    background-color: #f3f4f6;
  }
  
  .table-cell {
    padding: 1rem 1.5rem;
    text-align: left;
    font-size: 1rem;
    font-weight: 700;
    color: #111827;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .table-cell-center {
    text-align: center;
  }
  
  .table-body {
    border-top: 1px solid #e5e7eb;
  }
  
  .table-row {
    transition: background-color 0.2s ease-in-out;
  }
  
  .row-hover:hover {
    background-color: #f3f4f6;
  }
  
  .row-default {
    background-color: #ffffff;
  }
  
  /* Button */
  .action-button {
    padding: 0.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.15s ease-in-out, color 0.2s ease-in-out;
  }
  
  .action-button.enabled:hover {
    color: #3b82f6; /* Blue */
    transform: scale(1.05);
  }
  
  .action-button.disabled {
    cursor: not-allowed;
    color: #9ca3af; /* Gray */
  }
  
  /* Icon */
  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  /* Pagination Controls */
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #ffffff;
    border-top: 1px solid #e5e7eb;
  }
  
  .pagination-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .pagination-button.enabled {
    background-color: #6b7280;
  }
  
  .pagination-button.enabled:hover {
    background-color: #4b5563; /* Darker gray */
  }
  
  .pagination-button.disabled {
    background-color: #d1d5db; /* Disabled button */
    cursor: not-allowed;
  }
  
  .page-info {
    font-size: 1rem;
    color: #4b5563; /* Gray text */
  }
  