.not-found{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: 70vh;
}
.error-text-heading{
    font-size: 10rem;
}
.error-text-paragraph{
    font-size: 3rem;
    font-weight: 500;
}
.returnbtn {
    border: 1px solid black;
    padding: 5px 20px;
    background-color: #f0f0f0; /* Light background for contrast */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Soft shadow */
    border-radius: 5px; /* Rounded corners for a modern look */
    transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.returnbtn:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
}

.returnbtntext {
    text-decoration: none;
    color: #000;
    font-weight: bold; /* Make text bold for better visibility */
}

/* Media query for tablets and mobile devices */
@media (max-width: 1024px) {
    .error-text-heading {
        font-size: 6rem; /* Reduce size for tablets */
    }

    .error-text-paragraph {
        font-size: 2rem; /* Adjust paragraph font size */
    }

    .returnbtn {
        padding: 4px 15px; /* Smaller padding for button */
    }
}

@media (max-width: 768px) {
    .error-text-heading {
        font-size: 4rem; /* Reduce size further for smaller screens */
    }

    .error-text-paragraph {
        font-size: 1.5rem;
    }

    .returnbtn {
        padding: 3px 10px; /* Further reduce padding for mobile */
    }
}

@media (max-width: 480px) {
    .not-found {
        height: 50vh; /* Reduce height for very small screens */
    }

    .error-text-heading {
        font-size: 3rem; /* Further reduce size for mobile phones */
    }

    .error-text-paragraph {
        font-size: 1.2rem;
    }

    .returnbtn {
        padding: 2px 8px; /* Minimize button padding for compact layout */
    }
}
