.Deep_Dive {
  background: linear-gradient(180.36deg, #ffffff 67.32%, #ffffff 116.86%);
  backdrop-filter: blur(107px);
}

.main_div_deep_dive_heading {
  padding: 10px 50px;
}

.text_deep {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: left !important;
  /* identical to box height, or 72% */
  margin-left: 2.5rem;
  text-align: center;

  color: #65c8ff;
}

.lister_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  color: #1d1d1d;

  text-align: left;
}

.impact-card {
  background-color: white;
  border-radius: 10px;
  margin-top: 120px;
  padding: 20px;
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.impact-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.impact-section {
  margin-top: 20px;
}

.impact-item {
  margin-bottom: 15px;
}

.impact-item p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.main_deep_dive_row {
  display: flex;
  justify-content: space-between;
}

.deep_dive_first_col_container {
  height: 100vh;
  overflow: auto;
}

.deep_dive_first_col {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 140px;
  z-index: 1000;
}

.main_Deep_dive {
  width: 70%;
  padding: 75px 50px;
}

.deep_dive_list {
  list-style: none !important;
  list-style-type: none;
  padding: 0px;
  text-align: left;
}

.deep_dive_list li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  padding-left: 15px;
  cursor: pointer;

  /* identical to box height */

  padding-top: 15px;
  color: #1d1d1d;
}

.deep_dive_second_col h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  /* identical to box height, or 29px */

  text-align: left;
  color: #6b6b6b;
}

.deep_dive_second_col p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  text-align: left;
  /* or 29px */

  color: #6b6b6b;
}

.Text_Top_Deep {
  position: relative;
  width: 450.38px;
  height: 30.21px;
  /* left: 166px; */
  /* top: 80.39px; */

  /* gradient button */

  background: linear-gradient(
    90deg,
    #fd6542 8.19%,
    #e80370 49.3%,
    #5d0d56 94.64%
  );
  filter: blur(30px);
  border-radius: 138px;
  transform: rotate(-0.17deg);
}

.first_heading_deep .text_on_Deep_bg h6 {
  position: absolute;
  margin-top: -2rem;
  margin-left: -26rem;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #000000;
  font-size: 30px;
}

.first_heading_deep .text_on_Deep_bg h6 img {
  width: 30px;
}

.first_heading_deep {
  display: flex;
  align-items: center;
  margin-top: 6rem;
}

@media screen and (max-width: 992px) {
  .Predictions {
    position: relative;
    margin-top: 10px;
    color: #1d1d1d;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-top: 50px;
    width: 100% !important;
    overflow-x: scroll !important;
  }
  .landing .boxes {
    width: 750px;
    height: 100%;
    overflow-x: scroll !important;
  }
  .Text_Top_Deep {
    position: relative;
    width: 180.38px;
    height: 20.21px;
    /* left: 166px; */
    /* top: 80.39px; */

    /* gradient button */

    background: linear-gradient(
      90deg,
      #fd6542 8.19%,
      #e80370 49.3%,
      #5d0d56 94.64%
    );
    filter: blur(30px);
    border-radius: 138px;
    transform: rotate(-0.17deg);
  }

  .first_heading_deep .text_on_Deep_bg h6 {
    position: absolute;
    margin-top: -2rem;
    margin-left: -10rem;
    text-align: left;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #000000;
    font-size: 20px;
  }

  .first_heading_deep .text_on_Deep_bg h6 img {
    width: 20px;
  }

  .first_heading_deep {
    display: flex;
    align-items: center;
    margin-top: 6rem;
  }

  .shareIcon {
    width: 15px;
  }

  .deep_dive_list {
    text-align: center;
  }

  .lister_heading {
    text-align: center;
  }
}

.Active_Item {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000 !important;
  border-left: #e80370 5px solid;
  border-radius: 5px;
  padding-left: 15px;
}

.list_items::before {
  background: #e80370;
  content: "";
  border-radius: 5px;
  position: absolute;
  left: 0;
  width: 5px;
  height: 10%;
}

.deep_dive_second_col {
  text-align: left;
}
.text_on_Deep_bg h6 {
  text-align: left;
}

/* .main{
  border: 1px solid black;
  border-radius: 0px;
} */
.commentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sticky-col {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 140px;
  z-index: 1000;
}

.navigation-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.navigation-list li {
  margin-bottom: 10px;
}

.navigation-list div {
  text-decoration: none;
  text-align: left;
  color: #333;
  font-weight: bold;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
  transition: background-color 0.3s;
  cursor: pointer;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(194, 194, 194, 0.75);
  -moz-box-shadow: 0px 2px 4px 0px rgba(194, 194, 194, 0.75);
  box-shadow: 0px 2px 4px 0px rgba(194, 194, 194, 0.75);
}

.navigation-list div.active {
  background-color: #ff0000;
  /* Red color for active section */
  color: white;
  /* White text for contrast */
}

.navigation-list div:hover {
  background-color: #d0d0d0;
}

/* div.active first element */
.navigation-list div:first-child.active {
  background-color: black;
}

html {
  scroll-behavior: smooth;
}
