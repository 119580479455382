body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.underLineA {
  text-decoration: underline;
  color: gray;
  cursor: pointer;
}
.articalText {
  display: -webkit-box;
  text-align: justify;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
}
@media (max-width: 500px) {
  .articalText {
    text-align: left;
  }
}
.articlesContainer {
  overflow-y: scroll;
  height: 70vh !important;
}
.articlesContainer::-webkit-scrollbar {
  display: none;
}
.news-list::-webkit-scrollbar {
  display: none;
}
.titleArtical {
  font-size: large;
  font-weight: bold;
  color: darkgrey;
}
.containerFLEX {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.deep_text_langingg {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  display: inline-block;

  background: rgb(131, 58, 180);
  background: radial-gradient(
    circle,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
  background-position: 0 top;
  background-repeat: repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmer 1.4s infinite;
  background-size: 16rem 100%;
}
@keyframes shimmer {
  0% {
    background-position: -4rem 0;
  }
  100% {
    background-position: 12rem 0;
  }
}

.errorr {
  width: 100%;
}
