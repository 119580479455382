
.ant-carousel .slick-dots li,
.ant-carousel .slick-dots button {
    width: 54px!important;
    height: 7px!important;
    border-radius: 31px!important;
}

.ant-carousel .slick-dots li {
    background-color: #D9D9D9!important;
}

.ant-carousel .slick-dots-bottom {
    bottom: -26px !important;
}

.ant-carousel .slick-list .slick-slide>div>div h3 {
    height: auto !important;

    padding: 10px;

    
    /* padding-bottom: 2rem; */
    vertical-align: bottom;
    background-color: transparent !important;
}



.ant-carousel .slick-dots li.slick-active button {
    background: #000000;
    opacity: 10;
}

.ant-carousel .slick-dots li button {
    opacity: 0;
}


.slick-dots-bottom {
    justify-content: left!important;
}