@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.C2 {
    max-width: 100vw;
    overflow: hidden;
}

.C3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

@media (max-width: 600px) {
    .C3 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}



