.Icon_share {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 20px;
    width: 72px;
    height: 72px;
    background: rgba(157, 157, 157, 0.04);
    border-radius: 37.3333px;
    font-size: 50px;

}

.title_share {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    margin-top: 0.5rem;
    /* margin-left: 0.7rem; */
    color: #3D5A80;

}

.Icon_share:hover {
    background: rgba(238, 108, 77, 0.08);
    color: #EE6C4D;

}

.title_share:hover {
    color: #EE6C4D;
}

.share_menus.title_share:hover {
    color: #EE6C4D !important;

}
.Share_link{
    font-family: 'Estedad';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
/* identical to box height, or 125% */

text-align: center;

color: rgba(61, 90, 128, 0.5);
}
 .input.ant-input{
    background-color: #fff !important;
}
.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #EE6C4D;
    text-align: center;
    white-space: nowrap;
    background-color: #fff !important;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
}