.news-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto'!important;
    font-size: 15px!important;
    font-weight: 700!important;
    line-height: 27px!important;
    color: #6B6B6B!important;
    text-decoration: underline;
    text-align: left;
}

.news-link {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 25px!important;
    text-align: left;
    padding-left: 20px!important;
}

.news-link > a {
    color: #6B6B6B!important;
    text-decoration: none;
}

.news-link::before {
    content: "";
    position: absolute;
    left: 10px; /* adjust the positioning of the pointer as needed */
    top: 50%;
    transform: translate(-50%, -50%);
    height: 3px;
    width: 3px;
    background-color: #6B6B6B;
    border-radius: 50%;
}