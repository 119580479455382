.User_Header_bg {
    background: #191919ef;
    box-shadow: 22px -9px 45px rgba(0, 0, 0, 0.25);
}

.User_login_icon {
    font-size: 2rem;
    color: #fff !important;
}

.change_bg.ant-dropdown .ant-dropdown-menu {
    background: #181C25 !important;
    border: 1px solid #FFFFFF !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 6px !important;
    
}

.Drop_down_menu_items {
    font-family: 'Roboto';
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 140%;
    text-align: right;
    color: #FFFFFF !important;
 
    text-decoration: none;
    /* border: 2px solid #fa2525; */

}
.link_drop_down{
    padding: 0px 25px;
    
}

.link_drop_down:hover{
    border-left: 4px solid #FD6542;
    border-radius: 1px;

}
.nav_link:hover{
    color: #FFFFFF !important;
}
.nav_link:active{
    color: #FFFFFF !important;
}
.nav_link:focus{
    color: #FFFFFF !important;
}

.ant-tooltip {

width: max-content;
max-width: 200px !important;

}
.ant-tooltip .ant-tooltip-inner {
    min-width: 32px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: center !important;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}