.About_bg{
    padding-top: 10px;
}

.expand1 {
    font-family: 'Poppins', sans-serif;
    margin-top: 1rem;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;

    color: #525252;
}

.Text_Top_About2 {
    position: relative;
    width: 850.38px;
    height: 30.21px;
    /* left: 166px; */
    /* top: 80.39px; */

    /* gradient button */

    background: linear-gradient(90deg, #FD6542 8.19%, #E80370 49.3%, #5D0D56 94.64%);
    filter: blur(30px);
    border-radius: 138px;
    transform: rotate(-0.17deg);
}

.faq_spliter {
    display: flex;
    margin-top: 30px;
}

.faq_spliter > div {
    display: inline-block;
    width: 54px;
    height: 7px;
    border-radius: 31px;
    background-color: #D9D9D9;
    margin-right: 10px;
}

.About_bg .expander {
    display: none;
}

.About_bg .text {
    -webkit-line-clamp:inherit;
}


@media(max-width:992px) {
    .faq_spliter {
        display: none;
    }

    .About_bg .expander {
        display: inline-block;
    }

    .About_bg .text {
        -webkit-line-clamp: 4;
    }    
}