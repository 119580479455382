@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.Container1 {
  min-height: 100vh;
}

.C4 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.blogCategory {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.selected {
  border: 1px solid #b642b6;
}
.categories {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.highlightPost { 
  margin-left: "0";
  margin-top: "10%";
}

.BlogsContainer {
  padding-right: 7%;
}

.sideViewC6 {
  margin-left: 30px;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

@media (max-width: 600px) {
  /* Move search bar below blog heading */
  .Container1 {}

  .C4 {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .highlightPost {
    display: none;
    margin-top: 0px;
    height: 0px;
  }

  .sideViewC6 {
    margin-left: 0px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .BlogsContainer {
    padding-right: 0px;
  }
}



