.auth {
  // position: relative;
  width: 100%;
  height: 115vh;
  background-image: url("../image/LogIn_Bg.png");
  // background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: translate(calc(100% - 100%));

  box-shadow: 22px -9px 45px rgba(0, 0, 0, 0.25);

  .auth-mark {
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    // height: 50vh;
    .top {
      position: absolute;
      height: 200px;
      width: 200px;
      margin-left: -2rem;
    }
    .down {
      /* Polygon 3 */
      position: absolute;
      height: 150px;
      width: 90px;
      top: 70px;
    }
    .auth-mark-title-div {
      position: absolute;
      display: flex;
      flex-direction: row;
      .auth-mark-title-start {
        /* Your News - In a */
        position: relative;
        // width: 232px;
        height: 65px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 61px;
        text-align: center;

        color: #000000;
      }
      .auth-mark-title-end {
        /* Your News - In a */
        position: relative;
        width: 132px;
        height: 65px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 61px;
        text-align: center;

        color: #ffffff;
      }
    }
  }
  .auth-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900 !important;
    font-size: 30px;
    line-height: 36px;

    text-align: start;
    /* identical to box height */

    color: #191919;
  }

  .auth-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: start;
    /* identical to box height */
    color: #ffffff;
  }
  .auth-text-black {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    text-align: start;
    /* identical to box height */
    color: #000000;
  }
  .auth-input {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 20px;
    width: 300px;
    height: 50px;

    background: #191919;
    border-radius: 5px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    /* identical to box height */

    color: #ffffff;
  }
  .auth-input::-webkit-input-placeholder {
    color: #ffffff;
  }
  .auth-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    position: relative;
    width: 120px;
    height: 30px;
    margin: 5px;
    background: #191919;
    border-radius: 100px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 14px;
    /* identical to box height */

    color: #ffffff;
    border: none;
  }
  .auth-button:active {
    background-color: #0000008f;
    border: solid 1px #ffffff;
  }
  .auth-button:focus {
    background-color: #000000af;
    border: solid 1px #919191;
  }
  .auth-checkbox {
    position: relative;

    width: 16px;
    height: 16px;

    background: rgba(25, 25, 25, 0.2);
    box-shadow: 22px -9px 45px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }

  .signup {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    .signup-info-div {
      position: relative;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      .signup-info-div-x-space {
        width: 25%;
        height: 100%;
      }
      .signup-info-main-div {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .signup-info-main-top-space {
          width: 100%;
          height: 11%;
        }
        .signup-title {
          height: 8%;
        }
        .signup-text {
          height: 4%;
          width: 100%;
        }
        .signup-item-space {
          height: 7.5%;
          width: 100%;
        }
        .signup-item-space-half {
          height: 4.2%;
          width: 100%;
        }
        .signup-button {
          // height: 8.4%;
          // width: 40%;
          padding: 25px;
          margin-left: auto;
          margin-right: auto;
        }

        .signup-input {
          height: -1%;
          width: 100%;
          font-size: 16px;
          padding: 10px !important;
          color: #ffffff !important;
        }
        .signup-agree {
          display: flex;
          flex-direction: row;
          text-align: center;
          margin-top: -1rem;
        }
        .signup-account-question {
          text-align: center;
        }
        .signup-info-main-bottom-space {
          width: 100%;
          height: 13%;
        }
      }
    }
    .signup-mark-div {
      position: relative;
      width: 50%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }
  .login {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    .login-info-div {
      position: relative;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      // border: 1px solid red;

      .login-info-div-x-space {
        width: 25%;
        height: 100%;
      }
      .login-info-main-div {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .login-info-main-top-space {
          width: 100%;
          height: 11%;
        }
        .login-title {
          // height: 8%;
        }
        .login-text {
          height: 4%;
          width: 100%;
        }
        .login-item-space {
          height: 7.5%;
          width: 100%;
        }
        .login-item-space-half {
          height: 4.2%;
          width: 100%;
        }
        .login-button {
          // height: 8.4%;
          // width: 40%;
          padding: 27px;
          left: 30%;
        }

        .login-input {
          height: -1%;
          width: 100%;
          padding: 10px;
        }
        .login-agree {
          display: flex;
          flex-direction: row;
          text-align: center;
        }
        .login-account-question {
          text-align: center;
        }
        .login-info-main-bottom-space {
          width: 100%;
          height: 13%;
        }
      }
    }
    .login-mark-div {
      position: relative;
      width: 50%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }
  .forgotpwd {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    .forgotpwd-info-div {
      position: relative;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      .forgotpwd-info-div-x-space {
        width: 25%;
        height: 100%;
      }
      .forgotpwd-info-main-div {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .forgotpwd-info-main-top-space {
          width: 100%;
          height: 11%;
        }
        .forgotpwd-title {
          height: 8%;
        }
        .forgotpwd-text {
          height: 4%;
          width: 100%;
        }
        .forgotpwd-item-space {
          height: 7.5%;
          width: 100%;
        }
        .forgotpwd-item-space-half {
          height: 4.2%;
          width: 100%;
        }
        .forgotpwd-button {
          padding: 25px;
          left: 30%;
        }

        .forgotpwd-input {
          height: 8.4%;
          width: 100%;
        }
        .forgotpwd-agree {
          display: flex;
          flex-direction: row;
          text-align: center;
        }
        .forgotpwd-account-question {
          text-align: center;
        }
        .forgotpwd-info-main-bottom-space {
          width: 100%;
          height: 13%;
        }
      }
    }
    .forgotpwd-mark-div {
      position: relative;
      width: 50%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }
}
.auth-signup {
  background-image: url("../image/auth-bg-signup.png") !important;
}

@media screen and (max-width: 600px) {
  .auth {
    // position: relative;
    width: 100%;
    height: 130vh;
    background-image: url("../image/Signup_Respo.png");
    background-size: 122%;
    background-position: center;
    box-shadow: 22px -9px 45px rgba(0, 0, 0, 0.25);
  }
  .top {
    position: absolute;
    height: 150px !important;
    width: 150px !important;
    margin-left: -2rem;
    margin-top: -1rem;
  }
  .login {
    position: absolute;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    flex-direction: column !important;

    .login-info-div {
      position: relative;
      width: 100% !important;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      margin-top: 5rem;
      .login-info-div-x-space {
        width: 15% !important;
        height: 100%;
      }
      .login-info-main-div {
        width: 100% !important;
        height: 100%;
        display: flex;
        flex-direction: column;

        .login-button {
          // height: 30% !important;
          // width: 40%;
          // left: 30%;
        }
      }
    }
    .login-mark-div {
      position: relative;
      width: 100% !important;
      height: 20% !important;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }
  .signup-info-main-div .auth-input {
    height: 40px !important;
    font-size: 18px !important;
    margin-top: -0.5rem;
  }
  .login-info-main-div .auth-input {
    height: 48px !important;
    margin-top: 2rem;

    font-size: 18px !important;
  }
  .auth-mark-title-div {
    position: absolute;
    display: flex;
    flex-direction: row;
    .auth-mark-title-start {
      /* Your News - In a */
      position: relative;
      // width: 232px;
      height: 65px;

      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 40px !important;
      line-height: 61px;
      text-align: center;

      color: #000000;
    }
    .auth-mark-title-end {
      /* Your News - In a */
      position: relative;
      width: 132px;
      height: 65px;

      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 40px !important;
      line-height: 61px;
      text-align: center;

      color: #ffffff;
    }
  }
  .forgotpwd {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-direction: column !important;
    .forgotpwd-info-div {
      position: relative;
      width: 100% !important;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      margin-top: 5rem;
      .forgotpwd-info-div-x-space {
        width: 15% !important;
        height: 100%;
      }
      .forgotpwd-info-main-div {
        width: 100% !important;
        height: 100%;
        display: flex;
        flex-direction: column;

        .forgotpwd-title {
          height: 15% !important;
          font-size: 28px !important;
        }

        .forgotpwd-button {
          padding: 25px;
          left: 30%;
        }

        .forgotpwd-input {
          height: 12% !important;
          width: 100%;
          font-size: 18px;
        }
      }
    }
    .forgotpwd-mark-div {
      position: relative;
      width: 100% !important;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      margin-top: -3rem;
    }
  }

  .signup {
    position: absolute;

    display: flex;
    flex-direction: row-reverse;
    flex-direction: column-reverse !important;
    // margin-top: 25rem;

    .signup-info-div {
      position: relative;
      width: 100% !important;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      // margin-top: 10rem;
      .signup-info-div-x-space {
        width: 15% !important;
        height: 100%;
      }
      .signup-info-main-div {
        width: 100% !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        .signup-info-main-top-space {
          width: 100%;
          height: 11%;
        }
        .signup-title {
          height: 8%;
        }
        .signup-text {
          height: 4%;
          width: 100%;
        }
        .signup-item-space {
          height: 7.5%;
          width: 100%;
        }
        .signup-item-space-half {
          height: 4.2%;
          width: 100%;
        }
        .signup-button {
          margin-left: auto;
          margin-right: auto;
        }

        .signup-input {
          height: 7.4%;
          width: 100%;
        }
        .signup-agree {
          display: flex;
          flex-direction: row;
          text-align: center;
        }
        .signup-account-question {
          text-align: center;
        }
        .signup-info-main-bottom-space {
          width: 100%;
          height: 13%;
        }
      }
    }
    .signup-mark-div {
      position: relative;
      width: 100% !important;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      margin-top: -5rem;
    }
  }

  .auth-signup {
    background-image: url("../image/Signup_Respo.png") !important;
    height: 120vh !important;
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .auth {
    // position: relative;
    width: 100%;
    height: 120vh;
    background-image: url("../image/Signup_Respo.png");
    background-size: cover;
    background-position: center;
    box-shadow: 22px -9px 45px rgba(0, 0, 0, 0.25);
  }
  .login {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    // flex-direction: column !important;

    .login-info-div {
      position: relative;
      width: 100% !important;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      margin-top: 0rem;
      .login-info-div-x-space {
        width: 15% !important;
        height: 100%;
      }
      .login-info-main-div {
        width: 100% !important;
        height: 100%;
        display: flex;
        flex-direction: column;

        .login-button {
          // height: 30% !important;
          // width: 40%;
          // left: 30%;
        }
      }
    }
    .login-mark-div {
      position: relative;
      width: 100% !important;
      height: 100% !important;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }
  .signup-info-main-div .auth-input {
    height: 50px !important;
    font-size: 18px !important;
    margin-top: 2rem;
  }
  .login-info-main-div .auth-input {
    height: 60px !important;
    margin-top: 2rem;

    font-size: 18px !important;
  }
  .auth-mark-title-div {
    position: absolute;
    display: flex;
    flex-direction: row;
    .auth-mark-title-start {
      /* Your News - In a */
      position: relative;
      // width: 232px;
      height: 65px;

      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 40px !important;
      line-height: 61px;
      text-align: center;

      color: #000000;
    }
    .auth-mark-title-end {
      /* Your News - In a */
      position: relative;
      width: 132px;
      height: 65px;

      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 40px !important;
      line-height: 61px;
      text-align: center;

      color: #ffffff;
    }
  }
  .forgotpwd {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-direction: column !important;
    .forgotpwd-info-div {
      position: relative;
      width: 100% !important;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      margin-top: 5rem;
      .forgotpwd-info-div-x-space {
        width: 15% !important;
        height: 100%;
      }
      .forgotpwd-info-main-div {
        width: 100% !important;
        height: 100%;
        display: flex;
        flex-direction: column;

        .forgotpwd-title {
          height: 15% !important;
          font-size: 28px !important;
        }

        .forgotpwd-button {
          padding: 25px;
          left: 30%;
        }

        .forgotpwd-input {
          height: 12% !important;
          width: 100%;
          font-size: 18px;
        }
      }
    }
    .forgotpwd-mark-div {
      position: relative;
      width: 100% !important;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      margin-top: -3rem;
    }
  }

  .signup {
    position: absolute;

    display: flex;
    flex-direction: row-reverse;

    // margin-top: 25rem;

    .signup-info-div {
      position: relative;
      width: 100% !important;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      // margin-top: 10rem;
      .signup-info-div-x-space {
        width: 15% !important;
        height: 100%;
      }
      .signup-info-main-div {
        width: 100% !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        .signup-info-main-top-space {
          width: 100%;
          height: 11%;
        }
        .signup-title {
          height: 8%;
        }
        .signup-text {
          height: 4%;
          width: 100%;
        }
        .signup-item-space {
          height: 7.5%;
          width: 100%;
        }
        .signup-item-space-half {
          height: 4.2%;
          width: 100%;
        }
        .signup-button {
          margin-left: auto;
          margin-right: auto;
        }

        .signup-input {
          height: 7.4%;
          width: 100%;
        }
        .signup-agree {
          display: flex;
          flex-direction: row;
          text-align: center;
        }
        .signup-account-question {
          text-align: center;
        }
        .signup-info-main-bottom-space {
          width: 100%;
          height: 13%;
        }
      }
    }
    .signup-mark-div {
      position: relative;
      width: 100% !important;
      height: 150%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      margin-top: 5rem;
    }
  }
  .auth-mark {
    margin-top: -13rem !important;
  }

  .auth-signup {
    background-image: url("../image/auth-bg-signup.png") !important;
    height: 100vh !important;
    width: 100%;
  }
}
